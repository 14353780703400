import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { findFilterItemLabel } from '../../util/search';
import config from '../../config';

import css from './ListingPage.module.css';

const SectionSpecificationsMaybe = props => {
  const { publicData } = props;
  if (!publicData) {
    return null;
  }

  const selectedOptions = publicData && publicData.amenities ? publicData.amenities : [];

  const sport = findFilterItemLabel(
    'sport',
    config.custom.filters,
    publicData.sport,
  );

  const category = findFilterItemLabel(
    'category',
    config.custom.filters,
    publicData.category,
  );

  const subcategory = findFilterItemLabel(
    'subcategory',
    config.custom.filters,
    publicData.subcategory,
  );

  const useState = findFilterItemLabel(
    'useState',
    config.custom.filters,
    publicData.useState,
  );

  const size = findFilterItemLabel(
    'size',
    config.custom.filters,
    publicData.size,
  );

  const gender = findFilterItemLabel(
    'gender',
    config.custom.filters,
    publicData.gender,
  );

  const color = findFilterItemLabel(
    'color',
    config.custom.filters,
    publicData.color,
  );

  return (
    <div className={css.sectionFeatures}>
      <h2 className={css.sectionTitle}>
        <FormattedMessage id="ListingPage.specificationsTitle" />
      </h2>
      <ul>
        <li>
          <FormattedMessage id="ListingPage.specCategoryLabel" />
          <span>{': '}</span>
          {publicData.sport ? (<span>{sport}</span>) : null}
          <span>{' · '}</span>
          {publicData.category ? (<span>{category}</span>) : null}
          <span>{' · '}</span>
          {publicData.subcategory ? (<span>{subcategory}</span>) : null}
        </li>
        <li>
          <FormattedMessage id="ListingPage.specUseStateLabel" />
          <span>{': '}</span>
          {publicData.useState ? (<span>{useState}</span>) : null}
        </li>
        <li>
          <FormattedMessage id="ListingPage.specSizeLabel" />
          <span>{': '}</span>
          {publicData.size ? (<span>{size}</span>) : null}
        </li>
        <li>
          <FormattedMessage id="ListingPage.specBrandLabel" />
          <span>{': '}</span>
          {publicData.brand ? (<span>{publicData.brand}</span>) : null}
        </li>
        <li>
          <FormattedMessage id="ListingPage.specGenderLabel" />
          <span>{': '}</span>
          {publicData.gender ? (<span>{gender}</span>) : null}
        </li>
        <li>
          <FormattedMessage id="ListingPage.specColorLabel" />
          <span>{': '}</span>
          {publicData.color ? (<span>{color}</span>) : null}
        </li>
        <li>
          <FormattedMessage id="ListingPage.SpecNegotiableLabel" />
          <span>{': '}</span>
          {publicData.negotiable ? (
            <span>
              {publicData.negotiable === 'true' ? 'Negociable' : 'No negociable'}
            </span>
          ) : null}
        </li>
        <li>
          <FormattedMessage id="ListingPage.specExchangeLabel" />
          <span>{': '}</span>
          {publicData.exchange ? (
          <span>
            {publicData.exchange === 'true' ? 'Acepto intercambios' : 'No acepto intercambios'}
          </span>
        ) : null}
        </li>
      </ul>
    </div>
  );
};

export default SectionSpecificationsMaybe;

import React from 'react';
import { NamedLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const SectionGuides = props => {
  const { className } = props;

  return (
    <div className={className}>
      <h3 className={css.sectionTitle}>
        <FormattedMessage id="SectionGuides.title" />
      </h3>
      <p className={css.introSectionGuides}><FormattedMessage id="SectionGuides.intro" /></p>
      <ul className={css.sectionGuidesLinks}>
        <li><NamedLink name="BuyersGuidePage">
          <FormattedMessage id="SectionGuides.buyersLinkText" />
        </NamedLink></li>
        <li><NamedLink name="SellersGuidePage">
          <FormattedMessage id="SectionGuides.sellersLinkText" />
        </NamedLink></li>
      </ul>
    </div>
  );
}

export default SectionGuides;
